html {
  font-size: $main-fs;
  line-height: $main-lh;
}
body {
  background: $main-bg;
  background: var(--main-bg);
  color: $main-color;
  color: var(--main-color);
  font-family: $main-ff;
  font-size: 1rem;
  font-weight: $main-fw;
  line-height: $main-lh;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button, input, optgroup, select, textarea {
  font: inherit;
}
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $headers-ff;
  font-weight: $headers-fw;
  line-height: $main-lh;
  letter-spacing: .01em;
  margin-top: $module-rem * 2;
  margin-bottom: $module-rem * 2;
}
h1, .h1 {
  font-size: 3.2rem;
  line-height: 1.12;
  margin-bottom: $module-rem * 3;
}
h2, .h2 {
  font-size: 2.85714286rem;
  line-height: 1;
}
h3, .h3 {
  font-size: 2rem;
  line-height: 1.42857143;
}
h4, .h4 {
  font-size: 1.71428572rem;
  line-height: 1.25;
}
h5, .h5 {
  font-size: 1.42857143rem;
  line-height: 1.5;
}
h6, .h6 {
  font-size: 1rem;
}
p,
ul,
ol,
pre,
table,
blockquote,
.blockquote {
  margin-top: 0;
  margin-bottom: $module-rem * 2;

  &:last-child {
    margin-bottom: 0;
  }
}
ul ul, ol ol, ul ol, ol ul {
  margin-top: 0;
  margin-bottom: 0;
}
hr, .hr {
  border-top: 1px solid rgba(main-palette(500),.1);
  margin: ($module-rem * 2) 0;
}
sub, sup {
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
a {
  color: accent-palette(500);
  text-decoration: none;
  transition: color .2s $animation;

  &:hover,
  &:focus {
    color: accent-palette(700);
    outline: none;
    text-decoration: none;
  }
}
img {
  height: auto;
  max-width: 100%;
}
button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}
textarea {
  box-shadow: none;
}
mark,
.mark {
  padding: 0 .2em;
  background-color: accent-palette(500);
  color: accent-contrast(500);
}
::selection {
  background-color: accent-palette(500);
  color: accent-contrast(500);
}
::-moz-selection {
  background-color: accent-palette(500);
  color: accent-contrast(500);
}

//Scroll
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(#000,.1);
}
::-webkit-scrollbar {
  background: rgba(#fff,.2);
  position: absolute;
  width: 2px;
}
::-webkit-scrollbar:hover {
  display: block;
}
::-webkit-scrollbar-thumb {
  background-color: main-palette(100);
}
::-webkit-scrollbar:horizontal {
  height: 2px;
}
