.app-actions {
  align-items: center;
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
  margin: 0 !important;

  .item {
    margin: 0 $module-rem;

    .dropdown-toggle {
      color: inherit;
      display: block;

      .icon {
        font-size: 26px;
        opacity: .5;
        transition:
          background .2s $animation,
          border .2s $animation,
          color .2s $animation,
          opacity .2s $animation;
      }
      &:after {
        display: none;
      }
      .badge {
        border: 2px solid #fff;
        justify-content: center;
        min-width: 21px;
        padding-left: 2px;
        padding-right: 2px;
        position: absolute;
        right: -5px;
        text-align: center;
        top: -5px;
      }
      &:hover {
        .icon {
          opacity: 1;
        }
      }
    }
    .dropdown-menu {
      z-index: 9999;
    }
    &.show {
      .dropdown-toggle {
        .icon {
          color: accent-palette(500);
          opacity: 1;
        }
      }
    }
  }
}
