$_module-rem: $module-rem;
$_overlay-bg: rgba(main-palette(500),.1);
$_bg: #fff;
$_border-color: rgba(main-palette(500),.1);
$_shape: $shape;
$_transition: $animation;
$_shadow: $shadow;

.dropdown-toggle-split {
  padding-left: $_module-rem;
  padding-right: $_module-rem;
}
.dropdown-menu {
  background: $_bg;
  border: 0;
  border-radius: $_shape;
  box-shadow: $_shadow;
  margin: 0;
  padding: $_module-rem 0;

  .menu-list {
    font-size: 13px;
    list-style-type: none;
    margin: 0;
    padding: 10px 0;

    li {
      a {
        color: rgba(#333, .65);
        display: block;
        padding: 5px 15px;
        text-decoration: none;

        &:hover {
          color: #333;
        }
      }
    }
  }
  .menu-header,
  .menu-footer {
    margin: 0;
    padding: $_module-rem ($_module-rem * 2);
  }
  .menu-header {
    align-items: center;
    border-radius: $_shape $_shape 0 0;
    color: main-palette(500);
    display: flex;
    justify-content: space-between;
  }
  .menu-footer {
    border-radius: 0 0 $_shape $_shape;
  }
  .list {
    list-style-type: none;
    margin: 0;
    max-height: 280px;
    overflow: auto;
    padding: 0;

    > li {
      > a {
        color: $main-color;
        color: var(--main-color);
        display: flex;
        padding: $_module-rem ($_module-rem * 2);
        text-decoration: none;
        transition:
          background .2s $_transition,
          color .2s $_transition;

        .icon,
        .avatar,
        .tc-icon {
          align-self: flex-start;
          margin-right: $_module-rem;
        }
        .icon {
          background: rgba(main-palette(500),.1);
          border-radius: 50%;
          font-size: 1rem;
          height: auto;
          padding: $_module-rem;
          transition: background .2s $_transition;
          width: auto;
        }
        .avatar {
          border-radius: 50%;
          height: 40px;
          width: 40px;
        }
        .content {
          font-size: .85rem;

          .desc {
            display: block;
          }
          .date,
          .size {
            display: block;
            font-size: .75rem;
            margin-top: 4px;
            opacity: .6;
          }
          .meta {
            align-items: flex-end;
            display: flex;
            justify-content: space-between;
            line-height: 1.3;
            margin-bottom: 4px;

            .date {
              margin: 0 0 0 11px;
            }
          }
        }
        &:hover {
          color: accent-palette(500);

          .icon {
            background: accent-palette(500);
            color: accent-contrast(500);
          }
        }
      }
      &:first-child {
        > a {
          border-top: 0;
        }
      }
      .empty-item {
        display: block;
        padding: $_module-rem ($_module-rem * 2);
      }
    }
  }
  .author-info {
    padding: ($_module-rem * 2) ($_module-rem) 0;
    text-align: center;

    .author-name {
      font-size: 1rem;
    }
  }
  .dropdown-overlay {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: .1s opacity .2s $_transition;
    z-index: 9997;

    .overlay-bg {
      background: $_overlay-bg;
      display: block;
      height: 100%;
      width: 100%;
    }
  }
  &.dropdown-menu-w-180 {
    width: 180px;
  }
  &.dropdown-menu-w-280 {
    width: 280px;
  }
}
