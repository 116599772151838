@keyframes loaderAnimation{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}

.app-navbar {
  background: var(--navbar-bg);
  color: var(--navbar-color);
  display: block;
  min-height: $module-rem * 6;
  transition:
    background .2s $animation,
    box-shadow .2s $animation,
    color .2s $animation;
  will-change: box-shadow;

  .navbar-wrap {
    align-items: center;
    background: inherit;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    min-height: inherit;
    padding: 0 $module-rem;
    position: relative;

    > * {
      margin: 0 $module-rem;
    }
    .navbar-toggle {
      align-items: center;
      color: inherit;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 40px;
      justify-content: center;
      width: 35px;

      span {
        background: main-palette(500);
        display: block;
        height: 3px;
        margin: 4px 0;
        width: 35px;
      }
    }
    .navbar-close {
      color: inherit;
      cursor: pointer;
      font-size: 30px;
      height: auto;
      position: absolute;
      right: $module-rem;
      top: $module-rem * 1.5;
      width: auto !important;
      z-index: 1;
    }
    .add-patient {
      height: 100%;
      padding: ($module-rem * 2) ($module-rem * 4);
    }
    .assistant-menu {
      display: block;
      padding: 0 ($module-rem * 4) ($module-rem * 2);

      .link {
        color: inherit;
        display: block;
        font-weight: 700;
        opacity: .3;
        padding: ($module-rem / 2) 0;
        transition: opacity .2s $animation;

        .tc-icon {
          margin-right: $module-rem;
          padding: 0;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  body.boxed & {
    .navbar-wrap {
      max-width: $boxed-width;
      max-width: var(--boxed-width);
    }
  }
  &.horizontal-vertical {
    @media #{$max991} {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: translateX(-$vertical-navbar-width);
      transition: transform .2s $animation;
      width: $vertical-navbar-width;
      will-change: transform, width;
      z-index: 9997;

      .navbar-wrap {
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        overflow: auto;
        padding: 0;
        width: $vertical-navbar-width;

        > * {
          margin: 0;
          width: 100%;
        }
      }
      &.opened {
        transform: translateX(0);
      }
    }
    @media #{$min992} {
      min-height: $module-rem * 4;
    }
  }
  &.vertical {
    background: var(--navbar-2-bg);
    color: var(--navbar-2-color);
    display: flex;
    justify-content: flex-end;
    height: 100%;
    left: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    transition: transform .2s $animation;
    width: $vertical-navbar-width;
    will-change: transform, width;
    -webkit-overflow-scrolling: touch;
    z-index: 9997;
    @media #{$max991} {
      transform: translateX(-$vertical-navbar-width);
    }

    .navbar-wrap {
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      padding: 0;
      width: $vertical-navbar-width;

      > * {
        margin: 0;
        width: 100%;
      }
    }
    &.opened {
      transform: translateX(0);
    }
  }
  &.dark {
    background: var(--navbar-dark-bg);
    color: var(--navbar-dark-color);
  }
  .navbar-skeleton {
    align-items: center;
    background: inherit;
    display: flex;
    height: 100%;
    justify-content: space-between;
    left: 0;
    overflow: hidden;
    padding: 0 $module-rem;
    position: absolute;
    right: 0;
    top: 0;
    transition:
      .2s opacity 0s $animation,
      0s visibility 0s $animation;
    z-index: 999;

    body.loaded & {
      opacity: 0;
      transition:
        .2s opacity .2s $animation,
        0s visibility .4s $animation;
      visibility: hidden;
    }
    .bg {
      background: #ebebeb;
    }
    .bg-1 {
      background: #f5f5f5;
    }
    .left-part {
      flex-grow: 1;
    }
    .navbar-button {
      display: block;
      height: 30px;
      margin-right: $module-rem * 2;
      width: 35px;
    }
    .search {
      border-radius: 20px;
      display: block;
      height: 40px;
      max-width: 100%;
      width: 350px;
    }
    .icon-box {
      position: relative;

      .icon {
        border-radius: 5px;
        display: block;
        height: 20px;
        width: 20px;
      }
      .badge {
        background: $error-color;
        border: 0;
        border-radius: 20px;
        display: block;
        height: 16px;
        padding: 0;
        position: absolute;
        right: -8px;
        top: -10px;
        width: 16px;
      }
    }
    .avatar {
      border-radius: 20px;
      display: block;
      height: 40px;
      margin: 0 18px 0 25px;
      width: 40px;
    }
    .sk-logo {
      border-radius: 5px;
      display: block;
      height: $module-rem * 4;
      margin-right: $module-rem * 2;
      max-width: 169px;
      width: 100%;
    }
    .animated-bg {
      overflow: hidden;
      position: relative;
    }
    .animated-bg:before {
      animation-duration: 1.25s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: loaderAnimation;
      animation-timing-function: linear;
      background: transparent;
      background: linear-gradient(to right, transparent 10%, rgba(#fff,.5) 18%, transparent 33%);
      background-size: 800px 100%;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    &.vertical {
      flex-direction: column;

      .top-part,
      .bottom-part {
        padding: $module-rem ($module-rem * 3);
        width: 100%;
      }
      .bottom-part {
        padding-bottom: $module-rem * 3;
      }
      .sk-logo {
        margin: 0;
      }
      .sk-menu {
        margin: ($module-rem * 4.5) 0 0;

        .sk-menu-item {
          border-radius: 3px;
          display: block;
          height: $module-rem * 1.5;
          margin: ($module-rem * 1.5) 0 0;

          &.menu-header {
            width: 50%;
          }
        }
      }
      .sk-button {
        background: accent-palette(500);
        border-radius: 6px;
        height: $module-rem * 4;
        margin-top: $module-rem * 3;
      }
      .horizontal-menu {
        align-items: center;
        align-self: flex-start;
        display: none;
        height: 100%;
        margin: 0 $module-rem;

        .sk-menu-item {
          border-radius: 3px;
          display: block;
          height: $module-rem;
          margin: 0 $module-rem 0 0;
          width: $module-rem * 8;
        }
      }
    }
  }
  &.horizontal-vertical .navbar-skeleton {
    &.vertical {
      @media #{$min992} {
        .top-part,
        .bottom-part {
          display: none;
        }
        .horizontal-menu {
          display: flex;
        }
      }
    }
  }
}
