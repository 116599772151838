$_module-rem: $module-rem;
$_animation: $animation;
$_icon-fz: $main-lh * 1rem;
$_color: (
  default: #c1c1c1,
  hover: #ffeb3d,
  active: #faa110
);

.br-wrapper {
  .rating {
    &.rating-sm {
      + .br-widget {
        font-size: 1rem;
      }
    }
    &.rating-lg {
      + .br-widget {
        font-size: 2rem;
      }
    }
  }
  .br-widget {
    display: flex;
    font-size: $_icon-fz;
    margin: 0 (-$_module-rem / 4);
    white-space: nowrap;

    a {
      color: map-get($_color, default);
      cursor: pointer;
      display: block;
      font-size: inherit;
      font-style: normal;
      margin: 0 ($_module-rem / 4);
      transition:
        border .2s $_animation,
        color .2s $_animation;
      font-family: IcoFont!important;
      speak: none;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      line-height: 1;
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;

      &:after {
        content: '\f000';
        line-height: 1;
      }
      &.number {
        border: 2px solid map-get($_color, default);
        min-width: $_module-rem * 3;
        padding: calc(#{$_module-rem} / 2 - 2px);
        text-align: center;
      }
      &.br-active {
        color: map-get($_color, hover);

        &.number {
          border-color: map-get($_color, hover);
        }
      }
      &.br-selected {
        color: map-get($_color, active);

        &.number {
          border-color: map-get($_color, active);
        }
      }
    }
    &.rating-numbered {
      font-size: 1rem;
    }
    &.br-readonly {
      a {
        cursor: default;
      }
    }
  }
}
