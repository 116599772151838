$_module-rem: $module-rem;
$_shape: $shape;
$_shadow: $shadow;
$_title-fs: 2rem;
$_title-fw: 500;

.card {
  background: transparent 50% 50% no-repeat;
  background-size: cover;
  border: 0;
  border-radius: $_shape;
  display: block;
  margin-bottom: $_module-rem * 4;
  position: relative;

  .card-img {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    width: 100%;
  }
  .card-img-top {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  .card-img-overlay {
    overflow: auto;
  }
  .card-header {
    background: none;
    border: 0;
    font-size: $_title-fs;
    font-weight: $_title-fw;
    margin: 0;
    padding: 0;
  }
  .card-body {
    display: block;
    padding: ($_module-rem * 2) 0 0;
    position: relative;
    width: 100%;

    &:first-child {
      padding-top: 0;
    }
  }
  .overlay-img {
    border-radius: inherit;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  &.bg-primary,
  &.bg-secondary,
  &.bg-success,
  &.bg-danger,
  &.bg-warning,
  &.bg-danger,
  &.bg-info,
  &.bg-light,
  &.bg-dark,
  &.border-primary,
  &.border-secondary,
  &.border-success,
  &.border-danger,
  &.border-warning,
  &.border-danger,
  &.border-info,
  &.border-light,
  &.border-dark {
    box-shadow: $_shadow;

    .card-header {
      padding: $_module-rem ($_module-rem * 2) 0;
    }
    .card-body {
      padding: $_module-rem * 2;
    }
  }
  &.border-primary,
  &.border-secondary,
  &.border-success,
  &.border-danger,
  &.border-warning,
  &.border-danger,
  &.border-info,
  &.border-light,
  &.border-dark {
    background: none;
    border-style: solid;
    border-width: 1px;
  }
}
